<script setup lang="ts">
import { addMsgCallBack, start as janus, JanusIns, LocalEvType, ServerEvType } from 'janus-websdk';
import { markRaw, reactive, watch } from 'vue';

const initBody = {
  bussAppId: 103, rtcToken: "linjing@2023", rtcAppId: 2,
  // channel: 1010, rtcChannelId: "1010", liveId: 1010,
  // rtcUid: 10, connType: 0,
}
type InitBody = {
  "bussAppId": 103,
  "rtcToken": "linjing@2023",
  "rtcAppId": 2,
  "connType": 1,
  "rtcUid": 65111004,
  "rtcChannelId": "4001",
  "channel": 4001,
  "liveId": 4001,
  "peerUid": 35002004
}
const random = Date.now().toString().slice(-4)
localStorage.time ||= random

const state = reactive({
  rtcUid: random + localStorage.time.split('').reverse().join(''),
  rtcChannelId: localStorage.time,
  inputMsg: '',
  canShowMsgs: true,
  msgs: [] as { uid: number | '我', msg: string, time: string }[],
  linkState: 0 as 0 | 1,//断开,链接/连接中
  remoteVideoStreams: {} as Record<string, MediaStream>,
  videoStreamsMeta: {} as Record<string, InitBody & JanusIns>,
  localVideoOpen: true,
  localAudioOpen: true,
  remoteVideosDisable: {} as Record<string, boolean>,
  remoteAudiosDisable: {} as Record<string, boolean>,
  remoteUids: markRaw({}) as Record<string, string[] | undefined>,
  localStream: { audio: true, video: true }
})
const remoteAudioStreams = {} as Record<string, MediaStream>
const audioStreamsMeta = {} as Record<string, InitBody & JanusIns>

addMsgCallBack(LocalEvType.VIDEO_COMIN, ({ stream, id, initBody, janusIns, isLocal }) => {
  const { peerUid } = initBody
  if (isLocal || !peerUid) return
  state.remoteUids[peerUid] ||= []
  state.remoteUids[peerUid]!.push(id)
  state.remoteVideoStreams[id] = markRaw(stream)

  state.videoStreamsMeta[id] = markRaw(Object.assign(janusIns, initBody)) as any
})

const uninstallVideo = ({ id, isLocal }: any) => {
  if (isLocal) return
  delete state.remoteVideoStreams[id]
  delete state.videoStreamsMeta[id]
}

addMsgCallBack(LocalEvType.VIDEO_OFF, (...args) => console.log('视频断开', ...args))

addMsgCallBack(LocalEvType.AUDIO_COMIN, ({ stream, id, initBody, janusIns, isLocal }) => {
  const { peerUid } = initBody
  if (isLocal) return
  state.remoteUids[peerUid] ||= []
  state.remoteUids[peerUid]!.push(id)

  remoteAudioStreams[id] = stream
  audioStreamsMeta[id] = Object.assign(janusIns, initBody) as any

  const audioDom = document.createElement('audio')
  audioDom.id = id
  audioDom.srcObject = stream
  audioDom.autoplay = true
  audioDom.muted = true
  audioDom.style.visibility = 'hidden'
  audioDom.onplay = () => {
    audioDom.muted = false
    audioDom.onplay = null
  }
  document.body.appendChild(audioDom)
})

const uninstallAudio = ({ id, isLocal }: any) => {
  if (isLocal) return
  delete remoteAudioStreams[id]
  delete audioStreamsMeta[id]
  document.getElementById(id)?.remove()
}
addMsgCallBack(LocalEvType.AUDIO_OFF, (...args) => console.log('Audio断开', ...args))


addMsgCallBack(ServerEvType.JOIN, ({ peerUid }) => {
  console.error('有人加入了', { ...initBody, peerUid, connType: 1 })
  if (peerUid === +state.rtcUid) return console.error('收到了自己Feiji✈️')
  if (state.remoteUids[peerUid]) return console.error('重复加入')
  console.error((new Date()).toLocaleTimeString())
  state.remoteUids[peerUid] = []
  janusChildrenIns.push(janus({
    initBody: { ...initBody, peerUid, connType: 1 },
    localVideoContainer: document.getElementById('local'),
    // remoteVideoContainer: document.getElementById('remote'),
    server: 'https://ycrt.yunbzw.com/facertc/linjing',
    localAudioTrackOption: { recv: true, capture: false },
    localVideoTrackOption: { recv: true, capture: false },
  }))
  // TODO 没有加入失败的处理
})
addMsgCallBack(ServerEvType.LEAVE, ({ peerUid }) => {
  console.error(peerUid, 'Lik离开')
    ; (state.remoteUids[peerUid] || []).forEach(id => {
      uninstallAudio({ id })
      uninstallVideo({ id })
    })
  delete state.remoteUids[peerUid]
})
// @ts-ignore
addMsgCallBack(5, ({ uid, msg }) => {
  state.msgs.push({ uid, msg, time: new Date().toLocaleTimeString() })
})

watch(() => state.rtcChannelId, () => localStorage.time = state.rtcChannelId)

let janusIns: ReturnType<typeof janus>
const janusChildrenIns: ReturnType<typeof janus>[] = []

const start = () => {
  const { rtcUid, rtcChannelId } = state
  if (!rtcChannelId || !rtcUid) return console.error('参数不完整')
  state.linkState = 1
  Object.assign(initBody, {
    connType: 0,
    rtcUid: +rtcUid,
    rtcChannelId,
    channel: +rtcChannelId,
    liveId: +rtcChannelId
  })
  console.log('初始加入参数', initBody)
  janusIns = janus({
    initBody,
    localVideoContainer: document.getElementById('local'),
    // remoteVideoContainer: document.getElementById('remote'),
    server: 'https://ycrt.yunbzw.com/facertc/linjing',
    onData: function (data: any) {
      console.error('主要的收到消息', data)
    },
    localVideoTrackOption: {
      framerate: 20,
      bitrate: 1000000
    } as any
  })
}

const sendMsg = () => {
  // {"evType":5,"uid": 19, "msg": "gogogo"}
  const body = { evType: 5, uid: state.rtcUid, msg: state.inputMsg }
  state.msgs.push({ uid: '我', msg: state.inputMsg, time: new Date().toLocaleTimeString() })
  state.inputMsg = ''


  console.error('发哦送那个', JSON.stringify(body))
  janusIns.sendText(JSON.stringify(body))
}

enum MediaType {
  AUDIO = 1,
  VIDEO = 2,
  // ALL = 3,
}
enum MediaState {
  OFF = 3,
  ON = 4
}
type PluginHandle = {
  "session": {
    "localStreams": [],
    "destroyOnUnload": true,
    "pluginHandles": {}
  },
  "plugin": string
  "id": 1077698609930746,
  "token": null,
  "detached": false,
  "webrtcStuff": {
    "started": false,
    "myStream": null | MediaStream
    "streamExternal": false,
    "mySdp": {
      "type": string
      "sdp": string
    },
    "mediaConstraints": {},
    "pc": RTCPeerConnection
    "dataChannelOptions": {
      "ordered": true
    },
    "dataChannel": {
      "JanusDataChannel": {
        "pending": []
      }
    },
    "dtmfSender": null,
    "trickle": true,
    "iceDone": true,
    "bitrate": {
      "value": string
    },
    "volume": {},
    "remoteSdp": string
  },
  "mids": {
    "69b216a3-0be3-4db3-8422-5abac1c7e328": string
    "671307b2-d895-4c51-8512-976fac65cb91": string
  },
  muteAudio: (mid: string) => void
  muteVideo: (mid: string) => void
  unmuteAudio: (mid: string) => void
  unmuteVideo: (mid: string) => void
  send: (data: Record<string, any>) => void
  createOffer: (data: Record<string, any>) => void
}

const remoteMediaSwitch = (streamId: string, type: MediaType) => {
  const { original: originalJanusIns, channel, peerUid } = state.videoStreamsMeta[streamId]
  const mediaState = [, state.remoteAudiosDisable, state.remoteVideosDisable][type]!
  const nextState = !mediaState[streamId]
  mediaState[streamId] = nextState

  // const mid = streamId.slice(streamId.indexOf('_') + 1)

  // const pluginHandles: Map<number, PluginHandle> = originalJanusIns.pluginHandles
  // let pluginHandle: PluginHandle | null = null as any
  // pluginHandles.forEach(handle => {
  //   handle.mids[mid] && (pluginHandle = handle)
  // })

  // if (!pluginHandle) return
  // const transceiver = pluginHandle.webrtcStuff.pc.getTransceivers()
  //   .find(it => streamId.startsWith(it.receiver.track.kind))
  // if (!transceiver) return
  // transceiver.receiver.track.enabled = !nextState
  // console.error(transceiver)
  // const fnName = (nextState ? '' : 'un') + (type === MediaType.AUDIO ? 'muteAudio' : 'muteVideo')
  // pluginHandle[fnName](mid)
  // "channel": 1010, "localUid": 10, "peerUid": 11 
  const data = { "evType": nextState ? MediaState.OFF : MediaState.ON, "media": type, channel, peerUid, localUid: +state.rtcUid }
  console.error('fa发送', data, { streamId }, originalJanusIns)
  janusIns.sendText(JSON.stringify(data))
}
const localMediaSwitch = (type: MediaType) => {
  const key = type === MediaType.AUDIO ? 'audio' : 'video'
  const nextState = state.localStream[key] = !state.localStream[key]

  const pluginHandles: Map<number, PluginHandle> = janusIns.original.pluginHandles
  let pluginHandle: PluginHandle | null = null as any
  pluginHandles.forEach(handle => pluginHandle = handle)

  if (!pluginHandle) return
  // console.error('send', { message: { [key]: nextState } }, pluginHandle)

  // pluginHandle.send({ message: { [key]: nextState } })

  const transceiver = pluginHandle.webrtcStuff.pc.getTransceivers()
    .find(it => key === (it.receiver.track.kind))
  if (!transceiver || !transceiver.sender.track) return
  transceiver.sender.track.enabled = nextState
}

</script>

<template>
  <div class="row">
    <div class="col">
      <div class="mb-3">
        <label class="form-label">Uid</label>
        <input v-model="state.rtcUid" type="email" class="form-control" placeholder="Uid">
      </div>
    </div>
    <div class="col">
      <div class="mb-3">
        <label class="form-label">ChannelId</label>
        <input v-model="state.rtcChannelId" type="email" class="form-control" placeholder="ChannelId">
      </div>
    </div>
    <div class="col btns">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button @click="start" class="btn btn-primary mb-3" :disabled="state.linkState !== 0">加入频道</button>
        <button @click="() => {
          state.linkState = 0
          janusIns.stop()
          state.remoteUids = markRaw({})
          janusChildrenIns.forEach(it => it.stop())
        }" class="btn btn-primary mb-3" :disabled="state.linkState !== 1">退出频道</button>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="input-group" style="max-width: 500px;">
      <input type="text" class="form-control" v-model="state.inputMsg" placeholder="Msg">
      <button class="btn btn-primary " :disabled="!state.inputMsg || (state.linkState !== 1)" type="button"
        @click="sendMsg">发送</button>
    </div>
  </div>

  <div class="cards" v-show="state.linkState">
    <div class="card z-2" id="local">
      <div class="btns">
        <div class="btn" @click="localMediaSwitch(MediaType.VIDEO)"
          :style="`background-image:url(/camera-${state.localStream.video ? 'on' : 'off'}.svg)`" alt="">
        </div>
        <div class="btn" @click="localMediaSwitch(MediaType.AUDIO)"
          :style="`background-image:url(/mic-${state.localStream.audio ? 'on' : 'off'}.svg)`" alt="">
        </div>
      </div>
    </div>
    <div class="card" v-for="(stream, id) in state.remoteVideoStreams" :key="id" :id="id">
      <video v-if="!state.remoteVideosDisable[id]" class="video" autoplay muted :srcObject="stream"></video>
      <div class="peer-uid">{{ state.videoStreamsMeta[id].peerUid }}</div>
      <div class="btns">
        <div class="btn" @click="remoteMediaSwitch(id, MediaType.VIDEO)"
          :style="`background-image:url(/camera-${state.remoteVideosDisable[id] ? 'off' : 'on'}.svg)`" alt=""></div>
        <div class="btn" @click="remoteMediaSwitch(id, MediaType.AUDIO)"
          :style="`background-image:url(/mic-${state.remoteAudiosDisable[id] ? 'off' : 'on'}.svg)`" alt=""></div>
      </div>
    </div>

  </div>

  <div class="msgs card" v-if="state.msgs.length && state.canShowMsgs">
    <div class="msg" v-for=" (item, idx) in state.msgs" :key="idx + '' + item.uid">
      <div class="header">
        <cite class="small">{{ item.uid }}</cite>
        <cite class="small">{{ item.time }}</cite>
      </div>
      <div class="body" style="text-align: left;">{{ item.msg }} </div>
    </div>
  </div>
  <button v-if="state.msgs.length" class="btn btn-sm btn-primary btn--close " type="button"
    @click="state.canShowMsgs = !state.canShowMsgs">{{ state.canShowMsgs ? 'off' : 'on' }}</button>


</template>

<style lang="scss" scoped>
.cards {
  position: relative;

  .card {
    overflow: hidden;
  }
}

//#local {
//  width: 200px;
//  height: 150px;
//  position: absolute;
//  left: 0;
//  top: 0;
//  padding: 2em;
//}

.cards {
  display: grid;
  // grid-auto-columns: 400px;
  // grid-template-columns: (3, 400px);
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-auto-rows: 300px;
  gap: 10px;

  /* 这是网格项之间的间距，可选 */
  .card {
    width: 400px;
    height: 300px;
    position: relative;

    .video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .btns {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    gap: 20px;
    z-index: 2;


    .btn {
      border-radius: 26px;
      position: relative;
      width: 26px;
      height: 26px;

      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      background-blend-mode: multiply;

      background-color: white;
    }
  }

  .peer-uid {
    color: black;
    position: absolute;
    top: 0;
    left: 8px;
    letter-spacing: 1px;
    text-rendering: geometricPrecision;
    -webkit-text-stroke: 1px;
    /* 白色描边 */
    text-shadow: 0 0 1px white, 0 0 2px white, 0 0 3px white;
  }
}

.col {
  label {
    float: left;
    padding-left: 12px;
  }

  .btns {
    display: flex;
    align-items: end;
  }
}

.msgs {
  position: fixed;
  bottom: 50px;
  right: 30px;
  width: 260px;
  z-index: 9;
  padding: 12px;

  overflow-y: auto;
  max-height: 60vh;



  .msg {
    margin-bottom: 8px;

    .header {
      opacity: .7;
      display: flex;
      justify-content: space-between;
    }
  }
}

.btn--close {
  position: absolute;
  bottom: 10px;
  right: 30px;
}
</style>
